<template>
  <div class="wrap">
    <van-nav-bar :title="$t('home.title.notice')">
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
    </van-nav-bar>
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
import { NavBar, Icon, Toast } from "vant";
import { $post, $get } from "@/utils/request";
export default {
  components: { [NavBar.name]: NavBar, [Icon.name]: Icon },
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    async getContent() {
      Toast.loading();
      try {
        const res = await $get("/content/winnotice");
        Toast.clear();
        const { ret, msg, data } = res.data;
        if (ret === 1) {
          this.content = data[0].content;
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap{
  // background: #fff;
}
.content {
  padding: calc(14rem / 16);
  /deep/ a{
    word-break: break-all;
  }
  color: var(--theme);
}
</style>